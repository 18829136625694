import React from "react";

import Form from "components/Form";
import Heading from "components/Heading";
import { PhoneIcon, EnvelopeIcon } from "assets/icons";

import "./_contacts.scss";

const Contacts = ({ phone, email }) => {
  return (
    <div className="contacts">
      <div className="contacts__wrapper">
        <Heading headingLevel="h2" headingStyle="h3">
          Kaip galime Jums padėti?
        </Heading>
        <p className="contacts__text">
          Gal neradote atsakymo į jums rūpimus klausimus, ar reikia papildomos
          informacijos? Mes visada pasirengę jums padėti.
        </p>
        <div className="contacts__info-block">
          <div className="contacts__info-line">
            <div className="contacts__icon">
              <PhoneIcon />
            </div>
            <a
              className="contacts__link"
              href={`tel:${phone}`}
              rel="noopener noreferrer"
            >
              {phone}
            </a>
          </div>
          <div className="contacts__info-line">
            <div className="contacts__icon">
              <EnvelopeIcon />
            </div>
            <a
              className="contacts__link"
              href={`mailto:${email}`}
              rel="noopener noreferrer"
            >
              {email}
            </a>
          </div>
        </div>
      </div>
      <div className="contacts__form-wrapper">
        <Form />
      </div>
    </div>
  );
};

export default Contacts;
