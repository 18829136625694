import React from "react";
import Img from "gatsby-image";

import Heading from "components/Heading";

import "./_instagram.scss";

const Instagram = ({ heading, username, posts }) => {
  return (
    <div className="instagram">
      <Heading headingLevel="h2">{heading}</Heading>
      <p className="instagram__text">Pirmi sužinokite visas naujienas</p>
      <a
        href={`https://www.instagram.com/${username}/`}
        target="_blank"
        rel="noopener noreferrer"
        className="instagram__link"
      >{`@${username}`}</a>
      <div className="instagram__posts">
        {posts.map((post, id) => (
          <a
            key={id}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.instagram.com/${username}/`}
            className="instagram__post"
          >
            <Img fluid={post.fluid} alt="moduliniu namu statyba" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Instagram;
