import React from "react";
import { graphql } from "gatsby";

import { mainNavigation, homesNavigation } from "src/data";
import Header from "components/Header";
import Banner from "components/Banner";
import Layout from "components/Layout";
import Contacts from "components/Contacts";
import Instagram from "components/Instagram";
import Footer from "components/Footer";
import CookieBar from "components/CookieBar";

import "styles/styles.scss";

const IndexPage = ({ data }) => {
  const { logo, content, instagramPhotos } = data;
  return (
    <>
      <Header
        main={mainNavigation}
        homes={homesNavigation}
        logo={logo.childImageSharp.fluid}
        logoAlt="Moodu moduliniai nameliai logotipas"
      />
      {content.edges.map((item, idx) => (
        <React.Fragment key={idx}>
          <Banner
            heading={item.node.title}
            isInnerPage
            imageData={item.node.image.fluid}
            isCentered
          />
          <Layout>
            <Contacts
              phone={item.node.phone}
              email={item.node.email}
              facebook={item.node.facebook}
              instagram={item.node.instagram}
            />
            {instagramPhotos.edges.map((item, idx) => (
              <React.Fragment key={idx}>
                <Instagram
                  heading="Sekite mus Instagram"
                  username="moodu.eu"
                  posts={item.node.photos}
                />
              </React.Fragment>
            ))}
          </Layout>
          <Footer logo={logo.childImageSharp.fluid} />
          <CookieBar />
        </React.Fragment>
      ))}
    </>
  );
};

export const query = graphql`
  query Contact {
    logo: file(relativePath: { eq: "logo-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    content: allContentfulContact {
      edges {
        node {
          title
          subtitle
          phone
          email
          instagram
          facebook
          image {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    instagramPhotos: allContentfulInstagram {
      edges {
        node {
          photos {
            fluid(maxWidth: 320) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
