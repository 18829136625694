import React, { useState } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";

import "./_form.scss";

const Form = () => {
  const [state, setState] = useState({});
  const { register, handleSubmit, errors, reset } = useForm();
  const handleChange = e =>
    setState({ ...state, [e.target.name]: e.target.value });
  const [feedbackMsg, setFeedbackMsg] = useState(null);
  const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailClassName = classNames("form__input", {
    "form__input--error": errors.email,
  });
  const telClassName = classNames("form__input", {
    "form__input--error": errors.telefonas,
  });

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...state,
      }),
    })
      .then(response => {
        setFeedbackMsg(
          `Ačiū už Jūsų žinutę. Su Jumis susisieksime per artimiausias 12 valandų.`
        );
        reset();
      })
      .catch(error => {
        setFeedbackMsg(
          "Klaida. Žinutė nebuvo išsiųsta. Prašome pabandyti vėliau arba susisiekti kitu būdu."
        );
      });
  };

  return (
    <div className="form__container">
      <h2 className="form__heading">Susisiekite su mumis</h2>
      <form
        className="form"
        name="contact"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="subject" value="Nauja moodu.eu užklausa" />

        <div className="form__field">
          <label className="form__label" htmlFor="name">
            Vardas
          </label>
          <input
            type="text"
            name="vardas"
            id="name"
            onChange={handleChange}
            className="form__input"
          />
        </div>

        <div className="form__field">
          <label className="form__label" htmlFor="email">
            El. paštas*
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            ref={register({
              required: "Prašome įvesti savo el. paštą",
              pattern: {
                value: EMAIL_REGEX,
                message: "Netinkamas el. pašto adresas",
              },
            })}
            className={emailClassName}
          />
          <span className="form__error">
            {errors.email && `${errors.email.message}`}
          </span>
        </div>

        <div className="form__field">
          <label className="form__label" htmlFor="tel">
            Telefonas*
          </label>
          <input
            type="text"
            name="telefonas"
            id="tel"
            onChange={handleChange}
            ref={register({ required: true })}
            className={telClassName}
          />
          <span className="form__error">
            {errors.telefonas && "Nurodykite savo tel. numerį"}
          </span>
        </div>

        <div className="form__field">
          <label className="form__label" htmlFor="message">
            Žinutė
          </label>
          <textarea
            id="message"
            name="žinutė"
            onChange={handleChange}
            className="form__input form__input--textarea"
          ></textarea>
        </div>

        <button className="form__button" type="submit">
          Siųsti
        </button>
      </form>
      {feedbackMsg && <p>{feedbackMsg}</p>}
    </div>
  );
};

export default Form;
